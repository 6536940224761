<template>
  <SuccessModal>
    <h4 v-if="surveyCompletePercent >= 25" class="txt-32 txt-font-din-medium txt-bold">{{ $t('components.titles.surveyPlanningComplete') }}</h4>
    <h4 v-else class="txt-32 txt-font-din-medium txt-bold">{{ $t('components.titles.enumeratorComplete') }}</h4>
    <div class="flex justify-center w100 pb-40">
      <p v-if="surveyCompletePercent >= 25" class="txt-18">{{ $t('components.description.youCanTrackYourSurveyDataCollection') }}</p>
      <p v-else class="txt-18">{{ $t('components.description.completeAllSurveyPlanningTabs') }}</p>
    </div>
    <div class="flex justify-center w100 pb-40">
      <a-button
        class="mt-24 mr-10 w250 success-button"
        size="large"
        @click="onExit">
        {{ $t('components.description.okGotIt') }}
      </a-button>
      <router-link v-if="surveyCompletePercent >= 25" to="/">
        <a-button
          class="mt-24 w250 success-button-filled"
          size="large">
          {{ $t('components.description.takeMeToMyDashboard') }}
        </a-button>
      </router-link>
    </div>
  </SuccessModal>
</template>

<script>
import { mapGetters } from 'vuex';
import SuccessModal from '../../../success-modal.vue';

export default {
  name: 'FinishSurveyCreationModal',
  components: {
    SuccessModal
  },
  props: {
    onExit: {
      type: Function,
      required: true
    }
  },
  computed: {
    ...mapGetters(['surveyCompletePercent'])
  }
};
</script>

<style lang="scss">
.finish-survey-creation-modal {
  text-align: center;
  .ant-modal-body {
    padding: 0;
  }
}

.circle-outline {
  background: #66c300;
  width: 85px;
  height: 85px;
  border-radius: 50%;
  position: absolute;
  bottom: -42px;
  left: calc(50% - 42px);
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    color: white;
    font-size: 70px;
  }
}

.finish-header {
  position: relative;
  height: 95px;
  width: 100%;
  background: #66c300;
  margin-bottom: 80px;
}

.success-button {
  border: 2px solid #529e00;
  color: #529e00;
  border-radius: 1px;

  &:hover {
    border: 2px solid #529e00;
    color: #529e00;
    opacity: 0.5;
  }
}

.success-button-filled {
  border: 2px solid #529e00;
  background: #529e00;
  color: white;
  border-radius: 1px;

  &:hover {
    border: 2px solid #529e00;
    background: #529e00;
    color: white;
    opacity: 0.5;
  }
}
</style>
